import React from "react"
import { Link } from "gatsby"
import { Container, Jumbotron } from "react-bootstrap"
import styled from "@emotion/styled"

const CallToAction = styled(Jumbotron)`
  margin-bottom: 0;
  background: #343a40;
  padding: 4rem 2rem;
  border-radius: 0;
`

const Cta = ({
  headerTitle,
  headerText,
  showContactUs = true,
  showProducts = true,
}) => {
  let text = {
    __html:
      headerText ||
      `Have an enquiry? You may get in touch with us <br />
  via the link below, or have a look at our products page.`,
  }

  return (
    <CallToAction>
      <Container>
        <h2 className='text-center text-white mb-3'>
          {headerTitle || "Get In Touch With Us"}
        </h2>
        <p
          className='text-center text-white-2 mb-5'
          dangerouslySetInnerHTML={text}
        ></p>
        <div className='text-center'>
          {(() => {
            if (showContactUs) {
              return (
                <Link
                  to='/contact-us'
                  className='btn btn-primary d-block d-lg-inline-block mb-2 mb-lg-0 mx-lg-2 col-lg-3'
                >
                  Contact Us
                </Link>
              )
            }
          })()}

          {(() => {
            if (showProducts) {
              return (
                <Link
                  to='/products'
                  className='btn btn-outline-primary d-block d-lg-inline-block mb-2 mb-lg-0 mx-lg-2 col-lg-3'
                >
                  Our Products
                </Link>
              )
            }
          })()}
        </div>
      </Container>
    </CallToAction>
  )
}

export default Cta
