import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import { Jumbotron, Container, Row, Col, Card } from "react-bootstrap"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Cta from "../components/cta"

const Hero = styled(BackgroundImage)`
  min-height: 75vh;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  border-radius: 0;
`

const AboutUs = styled(Jumbotron)`
  background: #343a40;
  color: white;
  margin-bottom: 0;
  border-radius: 0;
`

const ProductCategories = styled(Container)`
  background: white;
  box-shadow: none;

  @media (min-width: 768px) {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .left,
  .right {
    padding: 3rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;
    @media (min-width: 768px) {
      box-shadow: none;
      margin-bottom: 0;
    }
  }

  .left {
    max-width: 590px;
    margin-left: auto;
  }
  .right {
    max-width: 590px;
  }

  .bg {
    min-height: 200px;
  }
`

const Brands = styled.div`
  padding: 4rem;
`

const TopProducts = styled(Jumbotron)`
  background: #fafafa;
  margin-bottom: 0;
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroBg: file(relativePath: { eq: "hero-bg.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1400
            grayscale: true
            quality: 90
            toFormat: JPG
            background: "#2b2b2b"
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      makentaSingleLogo: file(relativePath: { eq: "makenta-logo-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      acdelcoLogo: file(relativePath: { eq: "acdelco-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kozarLogo: file(relativePath: { eq: "kozar-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      midtronicsLogo: file(relativePath: { eq: "midtronics-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      batteryBg: file(relativePath: { eq: "battery-bg.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1000
            toFormat: JPG
            duotone: { highlight: "#fafafa", shadow: "#343a40" }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testersBg: file(relativePath: { eq: "testers-bg.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1000
            toFormat: JPG
            duotone: { highlight: "#fafafa", shadow: "#343a40" }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chargersBg: file(relativePath: { eq: "chargers-bg.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1000
            toFormat: JPG
            duotone: { highlight: "#fafafa", shadow: "#343a40" }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      topBattery: file(relativePath: { eq: "products/acdelco-m27.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: JPG) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      topCharger: file(relativePath: { eq: "products/acdelco-charger.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: JPG) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      topTester: file(relativePath: { eq: "products/midtronics-mdx-300.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: JPG) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout removeContainer>
      <SEO title='Home' />
      <div
        css={css`
          background: #2b2b2b;
        `}
      >
        <Hero
          className='py-4 py-md-4'
          loading='eager'
          fluid={data.heroBg.childImageSharp.fluid}
        >
          <Container>
            <h1 className='text-white text-center mb-4'>
              Malaysia's One-Stop Battery
              <br />
              Management Centre
            </h1>
            <p className='text-white text-center mb-5'>
              Delivering solutions for stand-by power since 1997. We supply,
              test and
              <br className='d-none d-lg-block' />
              &nbsp;service maintenance-free battery and cable products.
            </p>
            <div className='text-center'>
              <Link
                to='/products'
                variant='outline-primary'
                className='btn btn-outline-light mr-md-2 mb-md-0 mb-2 col-md-3'
              >
                Our Products
              </Link>
              <Link
                to='/contact-us'
                className='btn btn-primary ml-md-2 col-md-3'
              >
                Contact Us
              </Link>
            </div>
          </Container>
        </Hero>
      </div>
      <AboutUs>
        <Container>
          <Row>
            <Col sm='7' md='7' lg='7'>
              <h2 className='text-white'>About Us</h2>
              <p>
                We are the wholesale distributor for battery related products
                such as ACDELCO, MIDTRONICS and KOZAR. Makenta has a sales and
                distribution network throughout Malaysia.
              </p>
              <p>
                As a service-oriented company, we are committed to both
                resellers as well as customers, providing support from delivery
                right through to after-sales service.
              </p>
            </Col>
            <Col sm='5' md='5' lg='5'>
              <Img
                loading='eager'
                className='d-none d-sm-block mt-4 mt-lg-2 mx-auto'
                css={css`
                  @media screen and (min-width: 1024px) {
                    width: 300px;
                  }
                `}
                fluid={data.makentaSingleLogo.childImageSharp.fluid}
              />
            </Col>
          </Row>
        </Container>
      </AboutUs>
      <ProductCategories fluid>
        {/* BATTERIES ROW */}
        <Row
          className='mt-3 mt-sm-0'
          css={css`
            min-height: 35vh;
          `}
        >
          <Col sm='6' className='px-sm-0 order-1 order-sm-2 bg'>
            <BackgroundImage
              loading='eager'
              fluid={data.batteryBg.childImageSharp.fluid}
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </Col>
          <Col className='order-2 order-sm-1'>
            <div className='left'>
              <h3>Batteries</h3>
              <p>
                Full range of quality batteries for all requirements, large or
                small, basic or high-end. Our batteries are maintenance-free and
                fully-sealed to last longer, perform better at all temperatures.
              </p>
              <Link
                to='/products#batteries'
                variant='outline-dark'
                className='btn btn-primary col-sm-8 col-lg-5'
              >
                View Batteries ►
              </Link>
            </div>
          </Col>
        </Row>

        {/* TESTERS ROW */}
        <Row
          css={css`
            min-height: 35vh;
          `}
        >
          <Col sm='6' className='px-sm-0 order-1 order-sm-1 bg'>
            <BackgroundImage
              loading='eager'
              fluid={data.testersBg.childImageSharp.fluid}
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </Col>
          <Col className='order-2 order-sm-2'>
            <div className='right'>
              <h3>Testers</h3>
              <p>
                Fast, safe and accurate, our range of digital battery testers is
                the ultimate in diagnostic tools for your batteries.
              </p>
              <Link
                to='/products#testers'
                variant='outline-dark'
                className='btn btn-primary col-sm-8 col-lg-5'
              >
                View Testers ►
              </Link>
            </div>
          </Col>
        </Row>

        {/* CHARGERS ROW */}
        <Row
          css={css`
            min-height: 35vh;
          `}
        >
          <Col sm='6' className='px-sm-0 order-1 order-sm-2 bg'>
            <BackgroundImage
              loading='eager'
              fluid={data.chargersBg.childImageSharp.fluid}
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </Col>
          <Col className='order-2 order-sm-1'>
            {" "}
            <div className='left'>
              <h3>Chargers</h3>
              <p>
                We distribute the latest intelligent battery charger developed
                by Midtronics. Incorporating the charging and testing components
                while providing accurate and full analysis of battery condition.
              </p>
              <Link
                to='/products#chargers'
                variant='outline-dark'
                className='btn btn-primary col-sm-8 col-lg-5'
              >
                View Chargers ►
              </Link>
            </div>
          </Col>
        </Row>
      </ProductCategories>

      <Brands>
        <Container className='text-center'>
          <h2 className='mb-4'>Brands We Carry</h2>
          <Row className='justify-content-center'>
            <Col md='4' lg='3'>
              <Img
                loading='eager'
                className='mb-2'
                fluid={data.acdelcoLogo.childImageSharp.fluid}
              />
            </Col>
            <Col md='4' lg='3'>
              <Img
                loading='eager'
                className='mb-2'
                fluid={data.midtronicsLogo.childImageSharp.fluid}
              />
            </Col>

            <Col md='4' lg='3'>
              <Img
                loading='eager'
                className='mb-2'
                fluid={data.kozarLogo.childImageSharp.fluid}
              />
            </Col>
          </Row>
        </Container>
      </Brands>

      <TopProducts>
        <Container>
          <h2 className='text-center mb-4'>Top Sellers</h2>
          <Row className='justify-content-center'>
            <Col md='3'>
              <Card className='shadow border-0 mb-3 mb-lg-0'>
                <Img
                  loading='eager'
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.topBattery.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>ACDELCO M27</Card.Title>
                  <Card.Text>ACDELCO</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md='3'>
              <Card className='shadow border-0 mb-3 mb-lg-0'>
                <Img
                  loading='eager'
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.topTester.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>MDX P-300</Card.Title>
                  <Card.Text>MIDTRONICS</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md='3'>
              <Card className='shadow border-0 mb-3 mb-lg-0'>
                <Img
                  loading='eager'
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.topCharger.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>AD-0016</Card.Title>
                  <Card.Text>ACDELCO</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </TopProducts>

      <Cta />
    </Layout>
  )
}

export default IndexPage
